exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-nib-directors-tsx": () => import("./../../../src/pages/about-nib/directors.tsx" /* webpackChunkName: "component---src-pages-about-nib-directors-tsx" */),
  "component---src-pages-about-nib-financial-advice-tsx": () => import("./../../../src/pages/about-nib/financial-advice.tsx" /* webpackChunkName: "component---src-pages-about-nib-financial-advice-tsx" */),
  "component---src-pages-about-nib-nib-in-the-community-tsx": () => import("./../../../src/pages/about-nib/nib-in-the-community.tsx" /* webpackChunkName: "component---src-pages-about-nib-nib-in-the-community-tsx" */),
  "component---src-pages-about-nib-tsx": () => import("./../../../src/pages/about-nib.tsx" /* webpackChunkName: "component---src-pages-about-nib-tsx" */),
  "component---src-pages-adviser-plans-tsx": () => import("./../../../src/pages/adviser-plans.tsx" /* webpackChunkName: "component---src-pages-adviser-plans-tsx" */),
  "component---src-pages-adviser-tsx": () => import("./../../../src/pages/adviser.tsx" /* webpackChunkName: "component---src-pages-adviser-tsx" */),
  "component---src-pages-am-i-covered-tsx": () => import("./../../../src/pages/am-i-covered.tsx" /* webpackChunkName: "component---src-pages-am-i-covered-tsx" */),
  "component---src-pages-claims-tsx": () => import("./../../../src/pages/claims.tsx" /* webpackChunkName: "component---src-pages-claims-tsx" */),
  "component---src-pages-compare-plans-tsx": () => import("./../../../src/pages/compare-plans.tsx" /* webpackChunkName: "component---src-pages-compare-plans-tsx" */),
  "component---src-pages-complaints-tsx": () => import("./../../../src/pages/complaints.tsx" /* webpackChunkName: "component---src-pages-complaints-tsx" */),
  "component---src-pages-free-resources-article-404-tsx": () => import("./../../../src/pages/free-resources/article/404.tsx" /* webpackChunkName: "component---src-pages-free-resources-article-404-tsx" */),
  "component---src-pages-free-resources-tsx": () => import("./../../../src/pages/free-resources.tsx" /* webpackChunkName: "component---src-pages-free-resources-tsx" */),
  "component---src-pages-health-and-wellbeing-tsx": () => import("./../../../src/pages/health-and-wellbeing.tsx" /* webpackChunkName: "component---src-pages-health-and-wellbeing-tsx" */),
  "component---src-pages-health-insurance-tsx": () => import("./../../../src/pages/health-insurance.tsx" /* webpackChunkName: "component---src-pages-health-insurance-tsx" */),
  "component---src-pages-healthcheck-results-tsx": () => import("./../../../src/pages/healthcheck/results.tsx" /* webpackChunkName: "component---src-pages-healthcheck-results-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-life-cover-options-about-life-living-insurance-tsx": () => import("./../../../src/pages/life-cover-options/about-life-living-insurance.tsx" /* webpackChunkName: "component---src-pages-life-cover-options-about-life-living-insurance-tsx" */),
  "component---src-pages-life-cover-options-adviser-plans-tsx": () => import("./../../../src/pages/life-cover-options/adviser-plans.tsx" /* webpackChunkName: "component---src-pages-life-cover-options-adviser-plans-tsx" */),
  "component---src-pages-life-cover-options-contact-us-tsx": () => import("./../../../src/pages/life-cover-options/contact-us.tsx" /* webpackChunkName: "component---src-pages-life-cover-options-contact-us-tsx" */),
  "component---src-pages-life-cover-options-estimate-your-cover-tsx": () => import("./../../../src/pages/life-cover-options/estimate-your-cover.tsx" /* webpackChunkName: "component---src-pages-life-cover-options-estimate-your-cover-tsx" */),
  "component---src-pages-life-cover-options-for-homeowners-tsx": () => import("./../../../src/pages/life-cover-options/for-homeowners.tsx" /* webpackChunkName: "component---src-pages-life-cover-options-for-homeowners-tsx" */),
  "component---src-pages-life-cover-options-how-to-apply-tsx": () => import("./../../../src/pages/life-cover-options/how-to-apply.tsx" /* webpackChunkName: "component---src-pages-life-cover-options-how-to-apply-tsx" */),
  "component---src-pages-life-cover-options-kiwibank-tsx": () => import("./../../../src/pages/life-cover-options/kiwibank.tsx" /* webpackChunkName: "component---src-pages-life-cover-options-kiwibank-tsx" */),
  "component---src-pages-life-cover-options-special-offers-tsx": () => import("./../../../src/pages/life-cover-options/special-offers.tsx" /* webpackChunkName: "component---src-pages-life-cover-options-special-offers-tsx" */),
  "component---src-pages-life-cover-options-tsx": () => import("./../../../src/pages/life-cover-options.tsx" /* webpackChunkName: "component---src-pages-life-cover-options-tsx" */),
  "component---src-pages-life-cover-options-why-life-living-cover-is-important-tsx": () => import("./../../../src/pages/life-cover-options/why-life-living-cover-is-important.tsx" /* webpackChunkName: "component---src-pages-life-cover-options-why-life-living-cover-is-important-tsx" */),
  "component---src-pages-member-stories-tsx": () => import("./../../../src/pages/member-stories.tsx" /* webpackChunkName: "component---src-pages-member-stories-tsx" */),
  "component---src-pages-myhealthhq-closure-tsx": () => import("./../../../src/pages/myhealthhq-closure.tsx" /* webpackChunkName: "component---src-pages-myhealthhq-closure-tsx" */),
  "component---src-pages-news-and-media-news-details-404-tsx": () => import("./../../../src/pages/news-and-media/news-details/404.tsx" /* webpackChunkName: "component---src-pages-news-and-media-news-details-404-tsx" */),
  "component---src-pages-news-and-media-tsx": () => import("./../../../src/pages/news-and-media.tsx" /* webpackChunkName: "component---src-pages-news-and-media-tsx" */),
  "component---src-pages-ngatiwhatuaorakei-enrol-tsx": () => import("./../../../src/pages/ngatiwhatuaorakei/enrol.tsx" /* webpackChunkName: "component---src-pages-ngatiwhatuaorakei-enrol-tsx" */),
  "component---src-pages-ngatiwhatuaorakei-tsx": () => import("./../../../src/pages/ngatiwhatuaorakei.tsx" /* webpackChunkName: "component---src-pages-ngatiwhatuaorakei-tsx" */),
  "component---src-pages-non-pharmac-tsx": () => import("./../../../src/pages/non-pharmac.tsx" /* webpackChunkName: "component---src-pages-non-pharmac-tsx" */),
  "component---src-pages-offer-health-insurance-cn-tsx": () => import("./../../../src/pages/offer/health-insurance-cn.tsx" /* webpackChunkName: "component---src-pages-offer-health-insurance-cn-tsx" */),
  "component---src-pages-orbit-tsx": () => import("./../../../src/pages/orbit.tsx" /* webpackChunkName: "component---src-pages-orbit-tsx" */),
  "component---src-pages-orbitprotect-tsx": () => import("./../../../src/pages/orbitprotect.tsx" /* webpackChunkName: "component---src-pages-orbitprotect-tsx" */),
  "component---src-pages-our-products-tsx": () => import("./../../../src/pages/our-products.tsx" /* webpackChunkName: "component---src-pages-our-products-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-programme-enquiry-form-tsx": () => import("./../../../src/pages/programme-enquiry-form.tsx" /* webpackChunkName: "component---src-pages-programme-enquiry-form-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-special-offers-tsx": () => import("./../../../src/pages/special-offers.tsx" /* webpackChunkName: "component---src-pages-special-offers-tsx" */),
  "component---src-pages-tenehenehenui-enrol-tsx": () => import("./../../../src/pages/tenehenehenui/enrol.tsx" /* webpackChunkName: "component---src-pages-tenehenehenui-enrol-tsx" */),
  "component---src-pages-tenehenehenui-tsx": () => import("./../../../src/pages/tenehenehenui.tsx" /* webpackChunkName: "component---src-pages-tenehenehenui-tsx" */),
  "component---src-pages-using-your-cover-tsx": () => import("./../../../src/pages/using-your-cover.tsx" /* webpackChunkName: "component---src-pages-using-your-cover-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-news-details-tsx": () => import("./../../../src/templates/news-details.tsx" /* webpackChunkName: "component---src-templates-news-details-tsx" */)
}

